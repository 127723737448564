import { parseQueryString } from '@shein/common-function'
import { Button } from './Button.jsx'

export const oneClickPayPlugin = (ocpBillNo, ocpCallBack) => {
  let pluginContext = null
  let analysisConfig = {}
  let sourceIdentifier = ''

  /** 一键购配置 */
  const getOneClickPayInfo = (context) => {
    const { skuInfo } = context
    const { productDetailCheckOcbVO } = pluginContext?.useModules?.()?.addCartInfo.value || {}
    const { supportOneClickPay, skuSupportOneClickPayInfos } = productDetailCheckOcbVO || {}
    let showOneClickPayBtn = supportOneClickPay === '1'
    // sku 校验
    if (skuInfo?.sku_code) {
      showOneClickPayBtn = !!(skuSupportOneClickPayInfos?.find?.(item => item.skuCode === skuInfo.sku_code)?.supportOneClickPay == 1)
    }
    return {
      showOneClickPayBtn,
      billInfo: productDetailCheckOcbVO
    }
  }

  /** 依赖数据 */
  const getRelationInfo = (context) => {
    const { mallCode, productInfo, skuInfo, saleAttr } = context
    const { storeInfo, priceInfo, language } = pluginContext?.useModules?.() || {}
    const { salePrice, retailPrice, unitDiscount } = priceInfo.value || {}

    const baseInfo = { ...productInfo, salePrice, retailPrice, unitDiscount }
    const skcSaleAttr = saleAttr?.multiLevelSaleAttribute?.skc_sale_attr || []
    const add_source = parseQueryString(location.search)?.add_source

    // 一键购的额外请求参数
    const extraOneClickPayParams = {
      sku_code: skuInfo?.sku_code || '',
      mall_code: mallCode,
      amount: salePrice?.amount || '',
      add_source: add_source ? `detail_${add_source}` : '',
    }

    return {
      ...context,
      baseInfo,
      language: language.value,
      storeInfo: storeInfo.value,
      skcSaleAttr,
      extraOneClickPayParams,
      analysisConfig,
      sourceIdentifier,
      showAddCartBtn: !ocpBillNo
    }
  }

  return {
    name: 'OneClickPay',
    register: (context) => {
      pluginContext = context
    },
    beforeOpen: async context => {
      context.params.ocpBillNo = ocpBillNo
      analysisConfig = context.op.analysisConfig || {}
      sourceIdentifier = context.op.sourceIdentifier || ''
      return false
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 一键购配置
      const oneClickPayInfo = getOneClickPayInfo(context)
      // 依赖数据
      const relationInfo = getRelationInfo(context)

      // 非预售商品，且售罄或者库存不为0
      if(!unListed && !(isSoldOut || mallStock == 0) && oneClickPayInfo?.showOneClickPayBtn) {
        return Button({
          oneClickPayInfo,
          relationInfo,
          ocpCallBack,
        })
      }
    },
  }
}
