import { Button } from './Button.jsx'

/**
 * 立即购插件
 * @param {Object} data - 配置数据
 * @param {string} data.checkout_no - 结算号
 * @param {boolean} data.needJump - 是否需要跳转到订单结算页 (默认为false, 商详场景为true)
 * @param {boolean} data.autoGetCoupon - 是否自动领取优惠券 (默认为true, 商详主商品加车场景为false)
 * @param {boolean} data.autoUpdateCouponList - 是否自动更新优惠券列表 (默认为false, 商详场景为true)
 * @description 改功能必须在登录状态下才能使用
 * @description 该功能走的不是正常加车流程，不会触发加车流程的回调
 */
export const buyNowPlugin = (data, isShow) => {
  let pluginContext = null
  let analysisConfig = null
  return {
    name: 'BuyNow',
    beforeOpen: (data) => {
      analysisConfig = data?.op?.analysisConfig
      return false
    },
    register: (context) => {
      pluginContext = context
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，非售罄，并且mall库存大于0
      // 非定制商品
      // 非一键购商品
      if(isShow && !unListed && !isSoldOut && mallStock > 0) {
        return Button({
          context,
          checkout_no: data?.checkout_no,
          needJump: data?.needJump || false,
          autoGetCoupon: data?.autoGetCoupon || true,
          autoUpdateCouponList: data?.autoUpdateCouponList || false,
          analysisConfig,
          useModules: pluginContext?.useModules,
          text: pluginContext?.useModules?.().language?.value?.SHEIN_KEY_PWA_30145
        })
      }
    },
    callbacks: {
      onDrawerStatusChange: (drawerShow ) => {
        const { DetailBuynow } = pluginContext?.useModules?.().fsAbt?.value || {}
        const afterbuyroad = DetailBuynow?.p?.afterbuyroad === 'recommend'
        if (isShow && !drawerShow && afterbuyroad) {
          const timer = setTimeout(() => {
            clearTimeout(timer)
            window?._gb_app_?.$store.dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
          }, 500)
        }
      }
    }
  }
}
