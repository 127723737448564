
/**
 * 加车成功后业务(弹窗 / 滚动)
 */
export const addCartRecommendPlugin = (addCartRecommendPluginData) => {
  const store = window?._gb_app_?.$store || {}
  // 主销售属性数据
  let curExtraMainAttrData = {}

  // 是否需要滚动(弹窗会锁屏需要关闭后滚动)
  let showScroll = false

  // 滑动到商详情页推荐
  const setGoodsDetailScroll = () => {
    // 切换 skc 需要更新数据
    const cat_id = curExtraMainAttrData?.cat_id || store.getters['productDetail/common/cat_id']
    const goods_id = curExtraMainAttrData?.cat_id || store.getters['productDetail/common/goods_id']

    // 刷新推荐数据
    appEventCenter.$emit('recommend-refresh-data', { cat_id, goods_id }) 
    if (JSON.stringify(curExtraMainAttrData) !== '{}') {
      store.commit('newProductDetail/common/updateAsyncScrollFromReview', false, { root: true })
      store.commit('newProductDetail/common/updateAsyncScrollStatus', true, { root: true })
    } else {
      store.dispatch('productDetail/Recommend/set_goods_detail_scroll', {}, { root: true })
    }
  }

  return {
    name: 'addCartRecommend',
    afterAddCartApi: async (context) => {
      const { isMainProduct, addCartResult, setHiddenAddCartTips, productInfo, mallCode, skuInfo } = context
      const { addbagRecQuickShow, addbagRecAllShow } = store.getters['productDetail/Recommend/add_bag_recommend_config']
      const { needDelayScroll = false } = addCartRecommendPluginData || {}
      // 非主商品 / 加车失败
      if (!isMainProduct || addCartResult?.code !== '0') return

      // 加车成功后存储当前商品的主要信息
      const reqParams = {
        goods_id: productInfo.goods_id,
        quantity: addCartResult?.info?.quantity,
        mall_code: mallCode,
        sku_code: skuInfo?.sku_code || '',
        attrs: skuInfo?.sku_sale_attr || [],
      }
      store.dispatch('productDetail/addBag/update_add_to_bag_info', reqParams)
      store.dispatch('productDetail/addBag/show_outfit_content')

      // 加车成功后操作（弹窗 / 滚动到底下推荐）
      const afterAddCardRecommendAction = async () => {
        // 实验控制是否展示加车成功后推荐弹窗
        if(addbagRecQuickShow || addbagRecAllShow) {
          await store.dispatch?.('productDetail/Recommend/fetch_rec_data_actions', null, { root: true })
          const status = await store.dispatch?.('productDetail/Recommend/open_add_bag_recommend_drawer', null, { root: true })
          setHiddenAddCartTips(status)
          return !!status
        }
        return false
      }

      // 是否需要延迟
      if (needDelayScroll && window?._gb_app_) {
        // 监听评论页回到商详
        const unWatch = window._gb_app_.$watch(() => _gb_app_?.$store.state.productDetail.CommentPopup.view_comment, async(newVal) => {
          if (!newVal) {
            unWatch?.()
            const status = await afterAddCardRecommendAction()
            if (!status) {
              setGoodsDetailScroll()
            }
          }
        })
      } else {
        const status = await afterAddCardRecommendAction()
        showScroll = !status
      }
    },
    callbacks: {
      onMainAttrChange: (mainAttr = {}) => {
        curExtraMainAttrData = mainAttr
      },
      onDrawerStatusChange: (isShow) => {
        if (isShow) {
          // 复位
          curExtraMainAttrData = {}
          showScroll = false
          return
        }
        // 关闭弹窗后滚动
        const timer = setTimeout(() => {
          clearTimeout(timer)
          showScroll && setGoodsDetailScroll()
        }, 500)
      }
    }
  }
}
