import { Button } from './Button.jsx'
import { DisableButton } from './DisableButton.jsx'
import { FootBarTips } from './FootBarTips.jsx'

/**
 * 更新销售属性插件
 * @param {Function} updateCallback 更新销售属性回调
 * @description 销售属性模式
 */
export const salesAttrUpdatePlugin = (updateCallback) => {
  let pluginContext = null
  return {
    name: 'SalesAttrUpdate',
    register: (context) => {
      pluginContext = context
    },
    renderFooterBarTips: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，且售罄或者库存为0
      if(!unListed && (isSoldOut || mallStock == 0)) {
        return FootBarTips(pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_16794)
      }
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, mallStock, unListed } = context
      // 非预售商品，非售罄，并且mall库存大于0
      if(!unListed && !isSoldOut && mallStock > 0) {
        return Button({
          context,
          updateCallback,
          text: pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_15285
        })
      }
      // 非预售商品，且售罄或者库存为0
      if(!unListed && (isSoldOut || mallStock == 0)) {
        return DisableButton(pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_15285)
      }
    },
  }
}
