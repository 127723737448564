import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { h } from 'vue'

daEventCenter.addSubscriber({ modulecode: '1-7-1' })

export function Button(data) {
  function handleClick() {
    setTimeout(() => {
      SimilarInstance.showModal(data.productInfo, {
        similarFrom: 'out_of_stock'
      })
    }, 300)
    data?.closeQuickAddCart?.()
    // 找相似入口点击上报
    const { goods_id, goods_sn } = data.productInfo
    daEventCenter.triggerNotice({
      daId: '1-7-1-3',
      extraData: {
        sku: goods_sn,
        goods_id,
        activeFrom: 'quick_view',
        isOutOfStock: 1
      }
    })
  }
  return (
    <button
      class="mshe-btn-black"
      role="button"
      aria-label="Find Similar"
      onClick={() => handleClick()}
    >
      {data.text || 'Find Similar'}
    </button>
  )
}
