import { h } from 'vue'

export function Button(props) {
  const { context, text, updateCallback } = props

  async function handleClick() {
    const { checkSelected, setLoading, lockPage, removeLockPage, closeQuickAddCart } = context
    if (!checkSelected()) return
    try {
      // 锁定页面
      lockPage()
      setLoading(true)
      if (updateCallback) {
        await updateCallback(context)
      }
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    } catch (e) {
      console.log('salesAttrUpdatePlugin updateCallback error:', e)
      setLoading(false)
      // 解锁页面
      removeLockPage()
      closeQuickAddCart()
    }
  }

  return (
    <button
      class="mshe-btn-black"
      role="button"
      aria-label="Update"
      onClick={() => handleClick()}
    >
      {text || 'Update'}
    </button>
  )
}
