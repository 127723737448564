import { EstimatedTips } from './EstimatedTips.jsx'

/**
 * 到手价提示插件
 */
export const estimatedTipsPlugin = () => {
  return {
    name: 'EstimatedTips',
    renderFooterBarTips: (context) => {
      const { isSoldOut, mallStock, unListed, estimatedInfo } = context
      // 非预售商品，非售罄，并且mall库存大于0
      if(!unListed && !isSoldOut && mallStock > 0 && estimatedInfo && estimatedInfo.noThresholdFloor) {
        return EstimatedTips({ estimatedInfo })
      }
    },
  }
}
