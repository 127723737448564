
/**
 * 加车后隐藏默认弹窗
 */
export const hiddenAddCartTipsPlugin = () => {
  
  return {
    name: 'hiddenAddCartTips',
    afterAddCartApi: async (context) => {
      console.log(111111111, 'hiddenAddCartTips', context)
      const { setHiddenAddCartTips } = context
      // 加车后隐藏tips
      setHiddenAddCartTips(true)
    }
  }
}
