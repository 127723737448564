var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"productEstimatedTagNew",on:{"click":function($event){$event.stopPropagation();return _setup.emits('clickEstimatedTag')}}},[_c('div',{staticClass:"productEstimatedTagNew__main",class:{ 'productEstimatedTagNew_percentTypeStyleWrap': _vm.isWrap },style:({
      background: _setup.strategyStyle.mainBackground,
      color: _setup.strategyStyle.mainColor
    })},[_c('div',{staticClass:"productEstimatedTagNew__container"},[_c('div',{staticClass:"productEstimatedTagNew__estimated"},[_vm._v("\n        "+_vm._s(_vm.estimatedInfo.text)+"\n        "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isWrap),expression:"!isWrap"}],staticClass:"productEstimatedTagNew__left-arrow",class:{ 'productEstimatedTagNew__left-arrow_cssRight': _setup.cssRight }},[_c('svg',{attrs:{"width":"5px","height":"5px","viewBox":"0 0 5 5","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('g',{attrs:{"id":"页面-1","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('path',{attrs:{"id":"形状结合","d":"M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z","fill":_setup.strategyStyle.mainBackground}})])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWrap),expression:"isWrap"}],staticClass:"productEstimatedTagNew__top-arrow",style:({
            background: _setup.strategyStyle.mainBackground,
          })})]),_vm._v(" "),_c('div',{staticClass:"productEstimatedTagNew__percent",style:({
          background: _setup.strategyStyle.percentBackground,
          color: _setup.strategyStyle.percentColor
        })},[_vm._v("\n        "+_vm._s(_setup.discountPercent)+"\n      ")])])]),_vm._v(" "),_c('p',{staticClass:"productEstimatedTagNew__retail",style:(_vm.estimatedInfo.retailPriceStyle)},[_vm._v(_vm._s(_setup.retailPrice))]),_vm._v(" "),(_setup.hasLink)?_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2 price-new-estimated__sale-icon",style:(_vm.estimatedInfo.retailPriceStyle)}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }