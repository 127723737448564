<template>
  <button
    class="mshe-btn-black"
    role="button"
    aria-label="Buy Now"
    @click="handleBuyNow"
  >
    {{ text || 'Buy Now' }}
  </button>
</template>


<script lang="js">
import { defineComponent, watch } from 'vue'
import {
  buyNow,
  triggerSubmitBuyNow,
} from 'public/src/pages/product_app/store/modules/product_detail_new/utils/buyNow.js'
import { autoGetCoupon } from 'public/src/pages/product_app/store/modules/product_detail/utils/getCoupon'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })
export default defineComponent({
  name: 'BuyNowButton',
  props: {
    text: {
      type: String,
      default: 'Buy Now'
    },
    context: {
      type: Object,
      default: () => ({})
    },
    useModules: {
      type: Function,
      default: () => ({})
    },
    needJump: {
      type: Boolean,
      default: false
    },
    checkoutNo: {
      type: String,
      default: ''
    },
    autoGetCoupon: {
      type: Boolean,
      default: false
    },
    autoUpdateCouponList: {
      type: Boolean,
      default: false
    },
    analysisConfig: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    function showBuyNowReason() {
      const { addCartInfo, fsAbt, shipInfo, saleAttr } = props.useModules?.() || {}
      const skuList = saleAttr?.value?.multiLevelSaleAttribute?.sku_list || []
      const { skuInfo } = props.context || {}
      let { buyNowInfo } = addCartInfo?.value || {}
      let { appTraceReason, isQuickShipShowBuyNow, isShowBuyNow } =
      buyNowInfo || {}

      let isShow = false

      if (isShowBuyNow === '1') {
        isShow = true
      } else if (isQuickShipShowBuyNow === '1') {
        if (
          shipInfo?.hasQuickShipTime === '1' &&
        fsAbt?.value?.DetailPageQuickShipShow?.param == 'DetailShowQuick'
        ) {
          if (skuInfo?.sku_code) {
            isShow = skuInfo.isSupportQuickShip === '1'
          } else {
            isShow = skuList.every(item => item.isSupportQuickShip === '1')
          }
        }
      }
      return { isShow, reason: appTraceReason }
    }

    async function handleBuyNow() {
      let { reason, isShow } = showBuyNowReason()
      const { DetailPageQuickShipShow, DetailBuynow } =
      props.useModules?.().fsAbt?.value || {}
      let quickship_tp = 0
      if (!!props.context.quickShip) {
        quickship_tp = DetailPageQuickShipShow?.param == 'DetailShowQuick' ? 1 : 0
      } else {
        quickship_tp = 3
      }
      if (!props.context.checkSelected()) {
        triggerSubmitBuyNow({
          goods_id: props.context.productInfo.goods_id,
          mall_code: String(props.context.mallCode),
          quickship_tp,
          sku_code: '',
          sku_id: props.context.productInfo.goods_sn,
          result: 0,
          result_reason: 'Please select size',
          location: 'popup',
          is_buynow: isShow ? 1 : 0,
          nobuynow_reason: reason,
        })
        return
      }
      let { promotion_id, promotion_type } = props.analysisConfig || {}

      props.context.setLoading(true)
      const afterbuyroad = DetailBuynow?.p?.afterbuyroad === 'recommend'
      let data = await buyNow({
        language: gbCommonInfo.language,
        needJump: props.needJump,
        // 自定义跳转
        isCustomJump: true,
        mall_code: String(props.context.mallCode),
        promotion_id: promotion_id ? promotion_id : '',
        promotion_type: promotion_type ? promotion_type : '',
        quantity: props.context.quantity,
        sku_code: props.context.skuInfo?.sku_code,
        checkout_total: props.context.mainPrice,
        checkout_no: props.checkoutNo,
        buyNow: afterbuyroad ? 'afterbuyroad' : '',
        daData: {
          goods_id: props.context.productInfo.goods_id,
          quickship_tp,
          sku_id: props.context.productInfo.goods_sn,
          location: 'popup',
          is_buynow: isShow ? 1 : 0,
          nobuynow_reason: reason,
        },
      })
      // 自动领取优惠券
      if (props.autoGetCoupon) {
        await autoGetCoupon({
          couponInfo: props.useModules?.().couponInfo,
          estimatedInfo: props.context.estimatedInfo,
        })
        if (props.autoUpdateCouponList) {
          window?._gb_app_?.$store?.dispatch?.(
            'newProductDetail/updateEstimatedCouponList',
          )
        }
      }

      props.context.setLoading(false)
      if (data && data.code == 0) {
        props.context.closeQuickAddCart()
        if (data.jumpUrl) {
          location.href = data.jumpUrl
        }
      }
    }

    function triggerExposeBuyNow() {
      let { reason, isShow } = showBuyNowReason()
      setTimeout(() => {
        daEventCenter.triggerNotice({
          daId: '1-6-1-190',
          extraData: {
            activity_from: 'main',
            goods_id: props.context.productInfo.goods_id,
            location: 'popup',
            is_buynow: isShow ? 1 : 0,
            nobuynow_reason: reason
          }
        })
      }, 200)
    }

    watch(() => props.context?.productInfo?.goods_sn, (val) => {
      if(val && !props.context?.skuInfo?.sku_code) {
        triggerExposeBuyNow()
      }
    }, {
      immediate: true
    })

    watch(() => props.context?.skuInfo, () => {
      if(props.context?.skuInfo?.sku_code) {
        triggerExposeBuyNow()
      }
    })

    
    return { props, handleBuyNow }
  }

})
</script>
