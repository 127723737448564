<template>
  <Gifting
    v-if="giftInfo?.giftList?.length"
    class="quick-add__gifting"
    :language="language"
    :giftInfo="giftInfo"
    :loading="giftListLoading"
    addSource="quickaddtobag"
    @handleSelectGiftList="handleSelected"
  />
</template>

<script lang="js">
import { defineComponent, ref, computed, watch } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { Toast } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'

import qs from 'qs'

const { lang, language, SiteUID, shein_suggested_sale_price } = gbCommonInfo || {}
export default defineComponent({
  name: 'BelowSalesAttrGift',
  components: {
    Gifting: () => import('public/src/pages/goods_detail_v2/innerComponents/recommend/Gifting/index.vue'),
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
    pluginContext: {
      type: Object,
      default: () => ({}),
    },
    giftInfo: {
      type: Object,
      default: () => ({
        giftList: [],
        giftSelectedStatus: false,
        giftSelectedList: [],
        giftLowestPrice: '',
        giftSelectedPrices: '',
        productItemsLanguage: {},
      })
    },
    selectGiftList: {
      type: Function,
      default: () => {},
    },
    updateGiftInfo: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { productInfo, userInfo } = props.pluginContext?.useModules?.() || {}
    const isPaidUser = computed(() => userInfo?.value?.isPaidMember == '1')
    const giftGoodsSelectId = computed(() => productInfo?.value?.giftGoodsSelectId)
    const giftListLoading = ref(false)

    function handleSelected(data) {
      props.selectGiftList(data)
      if (data.getGiftPrice) {
        getSelectGiftPrices(data.selectedProducts)
      }
    }
   

    async function getGiftList(giftGoodsSelectId) {
      if (!giftGoodsSelectId) {
        props.selectGiftList({
          selectedStatus: false,
          selectedProducts: [],
        })
        return
      }
      let params = {
        select_id: giftGoodsSelectId,
        gift_goods_type: '1',
        limit: 50,
        page: 1,
      }
      const bffInfo = await schttp({
        method: 'POST',
        url: '/category/get_select_product_list',
        data: qs.stringify(params),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        useBffApi: true,
      })
      let { info: { products = [], lowestPrice = {} } = {} } = bffInfo || {}
      const suggestedSaleType = shein_suggested_sale_price?.[SiteUID] || ''
      products = products?.map(v => {
        return {
          ...goodsTransformation(v, {
            suggestedSaleType,
            language: language,
            lang: lang ?? 'us',
            isPaid: isPaidUser.value,
          }),
          _cartInfo: {
            selectStatus: false,
            isSoldOut: false,
            skuCode: v.sku_code,
          },
        }
      }) || []
      // 重置
      props.updateGiftInfo({
        giftList: products || [],
        giftLowestPrice: lowestPrice?.amountWithSymbol || '',
      })
      let selectedProducts = props.giftInfo.giftSelectedList
      let flag = selectedProducts.every(a => products.some(b => b.goods_id == a.goods_id))
      // 切换skc，选中的包材商品完全匹配新的包材列表，则保留选中状态，否则清空选中状态
      if (!flag) {
        // 选中的包材商品完全匹配新的包材列表，则保留选中状态，否则清空选中状态
        props.selectGiftList({
          selectedStatus: false,
          selectedProducts: [],
        })
      }
    }

    async function getSelectGiftPrices(selectedList) {
      if (selectedList.length === 0) return
      const { goods_id, goods_sn } = productInfo?.value || {}
      const selectedGoodsIdSkus = selectedList.map(d => `${d.goods_id},${d.mall_code},${d.sku_code}`).join('-')
      let params = {
        goodsId: goods_id,
        goodsSn: goods_sn,
        mallCode: props.context.mallCode,
        isAddCart: '1',
        isEnterDetailCall: '1',
        isMainGoodsSoldOut: '0',
        isPaidMember: isPaidUser.value ? '1' : '0',
        selectedGoodsIdSkus
      }
      giftListLoading.value = true
      schttp({
        method: 'GET',
        url: '/product/get_together_better_deals_price',
        params,
        useBffApi: true,
      }).then((bffInfo) => {
        giftListLoading.value = false
        props.updateGiftInfo({
          giftSelectedPrices: bffInfo?.info?.bundlePrice?.amountWithSymbol || ''
        })
        let { info: { bundlePrice = {} } = {}, code } = bffInfo || {}
        daEventCenter.triggerNotice({
          daId: '1-6-1-250',
          extraData: {
            result: code === '0' ? 1 : 0,
            reason: code === '0' ? '' : code,
            select_gift_num: selectedList.length,
            select_gift_price: bundlePrice?.amountWithSymbol || '',
          }
        })
        Toast(language.SHEIN_KEY_PWA_33564 || 'Add Successful!')
      }).catch(() => {
        giftListLoading.value = false
      })

    }

    watch(() => giftGoodsSelectId.value, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        getGiftList(newVal)
      }
    }, { immediate: true })
    return { props, giftListLoading, giftGoodsSelectId, language, getGiftList, handleSelected }
  }

})
</script>
