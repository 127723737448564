// LEVEL1: SHEIN 、ROMWE PC 各切一个中央站点：
const LEVEL1 = ["hk","mhk","rw","rwm"]
// LEVEL2：SHEIN 切 20% 站点、ROMWE  全量
const LEVEL2 = LEVEL1.concat(["id","mid","in","pwin","ru","mru","ma","pwma","vn","mvn","roe","mroe","ch","mch","se","mse","rwar","rwmx","rwau","rwca","rwit","rwes","rwde","rwfr","rwuk","rwus","rwmar","rwmmx","rwmau","rwmca","rwmit","rwmes","rwmde","rwmfr","rwmuk","rwmus"])
// LEVEL3: SHEIN 切 40% 站点
const LEVEL3 = LEVEL2.concat(["asia","masia","my","mmy","th","mth","tw","mtw","ph","mph","pt","mpt","nl","pwnl","pl","mpl"])

const levelMapping = {
  'LEVEL1': LEVEL1,
  'LEVEL2': LEVEL2,
  'LEVEL3': LEVEL3,
}

/** 是否使用新加车弹窗 */
export const canUseNewQuickAdd = (key) => {
  let { SiteUID: siteUid, NEW_QUICK_ADD_SWITCH: apolloConfig } = gbCommonInfo

  if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.NODE_SERVER_ENV !== 'production') {
    if(window.localStorage.getItem('__useQuickAddCart__') === '1') return true
  }
  
  const allowedSites = apolloConfig?.[key] || null
  if (!apolloConfig || !allowedSites) {
    return false
  }

  if (Array.isArray(allowedSites) && allowedSites?.length === 1) {
    const allowedSite = allowedSites[0]
    return allowedSite === 'OPENALL' || allowedSite === siteUid || (levelMapping[allowedSite]?.includes(siteUid) || false)
  }
  return allowedSites.includes(siteUid)
}
