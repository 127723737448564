import { Button } from './Button.jsx'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export const findSimilarPlugin = () => {
  let pluginContext = null
  return {
    name: 'FindSimilar',
    register: (context) => {
      pluginContext = context
    },
    languages: () => {
      return ['SHEIN_KEY_PWA_16794', 'SHEIN_KEY_PWA_16789']
    },
    renderAddCartButton: (context) => {
      const { isSoldOut, unListed, productInfo, closeQuickAddCart } = context
      // 非预售商品，且售罄
      if(!unListed && (isSoldOut)) {
        // 主商品价格
        const { salePrice, retailPrice } = pluginContext?.useModules?.()?.priceInfo.value || {}
        const productBaseInfo = {
          ...productInfo,
          salePrice,
          retailPrice
        }
        return Button({
          productInfo: productBaseInfo,
          closeQuickAddCart,
          text: pluginContext?.useModules?.()?.language?.value?.SHEIN_KEY_PWA_16789
        })
      }
    },
    callbacks: {
      onSubAttrClick: (data) => {
        const { type, value }  = data
        // 尺码反馈点击找相似
        if (type === 'findSimilar') {
          const { productInfo, priceInfo } = pluginContext?.useModules?.() || {}
          const productBaseInfo = {
            ...productInfo?.value,
            salePrice: priceInfo?.value?.salePrice,
            retailPrice: priceInfo?.value?.retailPrice
          }
          SimilarInstance.showModal(productBaseInfo, {
            similarFrom: 'out_of_stock',
            filterSize: value?.filterSize,
          })
          // 上报埋点
          daEventCenter.triggerNotice({
            daId: '1-6-1-59',
            extraData: {
              goods_id: productInfo?.value?.goods_id,
              size: value?.filterSize,
            },
          })
        }
      }
    }
  }
}
