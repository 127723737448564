/**
 *  加车弹窗数据同步(仅商详使用) 
 *  快速加车弹窗跟商详页的数据同步都在插件内处理，回调中处理埋点、业务逻辑
 */
export const syncDetailsDataPlugin = () => {
  const store = window?._gb_app_?.$store || {}
  let pluginContext = null
  // 主销售属性数据
  let curExtraMainAttrData = {}
  // mallInfo 数据
  let curExtraMallData = null 

  // 校验是否主商品(主商品才需要数据同步)
  const checkIsMainGoods = () => {
    // 主商品
    const mainGoodsId = store.getters['productDetail/common/goods_id']
    const productInfo = pluginContext?.useModules?.()?.productInfo.value || {}
    return mainGoodsId === productInfo?.goods_id
  }

  // 校验是否SPU
  const checkSpu = () => {
    const productRelationId = store.getters['productDetail/common/product_relation_id']
    const productInfo = pluginContext?.useModules?.()?.productInfo.value || {}
    return productRelationId === productInfo?.productRelationID
  }

  return {
    name: 'syncDetailsData',
    register: (context) => {
      pluginContext = context
    },
    callbacks: {
      // 次级销售属性数据同步
      onSubAttrChange: ({ value = {} }) => {
        if (!checkIsMainGoods()) return 
        const { attrs, skuInfo, externalSizeInfoIndex } = value
        const addToBagFormReal = store.state?.newProductDetail?.common?.addToBagForm
        addToBagFormReal.attrs = attrs
        if (window.vBus) {
          window.vBus.$emit('changeWakeupData', {
            sku_code: skuInfo?.sku_code || '',
            selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
          })
        }
        store.commit('newProductDetail/common/updateSkuInfo', skuInfo, { root: true })
        store.commit('newProductDetail/common/updateAddToBagForm', addToBagFormReal, { root: true })
        store.commit('newProductDetail/common/updateExternalSizeInfoIndex', externalSizeInfoIndex, { root: true })
      },
      // 次级销售属性点击
      onSubAttrClick: ({ type = '', value = {} }) => {

        if (type === 'localChange') {
          store.commit('newProductDetail/common/updateCurrentLocalCountry', value, { root: true })
        }

        if (!checkIsMainGoods()) return 
        
        if (type === 'perfectFitShowStatus') {
          return store.commit('newProductDetail/SizeBox/updateGlobalPerfectFitShow', value, { root: true })
        }

        if (type === 'sizeRecommend') {
          const rule_type = store.getters['productDetail/SizeGuide/rule_type']
          if (rule_type === '1' ) {
            store.dispatch('productDetail/SizeBox/open_bra_size_recommend_pop', null, { root: true })
          } else {
            store.dispatch('productDetail/SizeBox/open_size_recommend_pop', null, { root: true })
          }
          return
        }
      },
      // 色块数据同步
      onMainAttrChange: (mainAttr = {}, immediateUpdate) => {
        // 大小码切换spu不一致
        if (checkSpu() || immediateUpdate) {
          curExtraMainAttrData = mainAttr
        }
      },
      // mallCode 数据同步
      onMallClick: (mallInfo = {}) => {
        if (!checkIsMainGoods()) return 
        curExtraMallData = mallInfo
      },
      // Quantity 数据同步
      onQuantityChange: (quantity = 1) => {
        if (!checkIsMainGoods()) return 
        const add_to_bag_form = store.getters['productDetail/common/add_to_bag_form'] || {}
        store.commit('newProductDetail/common/updateAddToBagForm', {
          ...add_to_bag_form,
          quantity: quantity === 0 ? 1 : quantity,
        }, { root: true })
      },
      // 收藏状态同步
      onFavoriteStatusChange: ({ wishStatus }) => {
        if (!checkIsMainGoods()) return 
        const initExposeStatus = store.state.newProductDetail.common?.initExposeStatus
        store.commit('newProductDetail/common/updateSaveStatus', wishStatus, { root: true })
        store.dispatch('newProductDetail/common/updateExposeStatus', {
          addWishStatus: wishStatus && 1,
          addBagStatus: initExposeStatus?.addBagStatus || 0,
        }, { root: true })
      },
      // 点击价格
      onPriceClick: () => {
        store.dispatch('productDetail/price/quick_add_click_estimated_tag', { skcInfo: curExtraMainAttrData }, { root: true })
      },
      // 加车成功
      onAddCartComplete: ({ isSuccess }) => {
        if (!checkIsMainGoods() || !isSuccess) return 

        store.commit('newProductDetail/common/updateAddCompleteStatus', true, { root: true })
        store.commit('newProductDetail/common/updateAddSkcCompleteStatus', true, { root: true })

        // 加车成功后存储当前商品的主要信息
        const skuInfo = pluginContext?.useModules?.()?.skuInfo?.value || {}
        store.dispatch('newProductDetail/common/updateAddToBagInfo', { sku_code: skuInfo?.sku_code }, { root: true })

        setTimeout(() => {
          window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
        }, 2000)

        const initExposeStatus = store.state.newProductDetail.common?.initExposeStatus
        store.dispatch('newProductDetail/showOutfitContent', null, { root: true })
        store.dispatch('newProductDetail/common/updateExposeStatus', {
          addBagStatus: 1,
          addWishStatus: initExposeStatus?.addWishStatus || 0,
        }, { root: true })
      },
      // 弹窗状态
      onDrawerStatusChange: (isShow) => {
        store.commit('productDetail/addBag/update_quick_add_state', !!isShow, { root: true })
        // store.commit('newProductDetail/common/updateQuickAddState', !!isShow, { root: true })
        if (isShow) {
          // 重新打开重置
          curExtraMainAttrData = {}
          curExtraMallData = null
          return
        }

        // 更新 mall 数据 
        if (curExtraMallData) {
          const { mallCode } = curExtraMallData
          store.commit('newProductDetail/SizeBox/updateSwitchMallCode', mallCode, { root: true })
        }
        // 更新色块数据
        if (JSON.stringify(curExtraMainAttrData) !== '{}') {
          appEventCenter.$emit('close-popover-add-bag', curExtraMainAttrData)
          // 更新后删除，专场后会有问题
          curExtraMainAttrData = {}
        }
        // 更新 local unit
        store.dispatch('productDetail/SizeBox/update_local_unit', null, { root: true })
      },
      // 领券后
      onCouponBindFinish: (data) => {
        const { isSuccess, mallCode, productInfo } = data || {}
        if(isSuccess) {
          // 刷新商详页券列表
          store.dispatch('newProductDetail/asyncHotModles', {
            goods_id: productInfo?.goods_id,
            mallCode,
          }, { root: true })
        }
      }
    }
  }
}
