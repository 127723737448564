import { SIMetric, SILogger } from 'public/src/pages/common/monitor/index.js'

const monitorMap = {
  1: 'add_to_cart_pop_up_total', // 加车弹窗触发次数
  2: 'add_to_cart_pop_up_trigger_total', // 加车弹窗成功唤起次数
  3: 'add_to_cart_pop_up_conversion_total', // 加车弹窗成功加车次数
  4: 'add_to_cart_pop_up_bounce_total', // 加车弹窗跳出次数
  5: 'add_to_cart_click_add_cart_total' // 点击加车按钮次数
}

/**
 * 根据采样率判断是否执行操作
 * @returns { Boolean } 是否执行操作
 */
const hitMonitor = () => {
  const { GOODS_DETAIL_MONITOR_RANDOM = 0.1, isDebug } = gbCommonInfo || {}
  // 生成一个0到1之间的随机数
  const randomValue = Math.random()
  // 如果随机数小于等于采样率，生效
  if (randomValue <= GOODS_DETAIL_MONITOR_RANDOM) {
    isDebug && console.log('操作生效 ✅，采样率:', GOODS_DETAIL_MONITOR_RANDOM, '随机值:', randomValue)
    return true
  } else {
    isDebug && console.log('操作不生效 ❌，采样率:', GOODS_DETAIL_MONITOR_RANDOM, '随机值:', randomValue)
    return false
  }
}

/**
 * 初始化快速加车监控
 * @param {*} param0 
 * @returns 
 */
const initMonitor = ({ scene, version = 'old' }) => {
  const isHitMonitor = hitMonitor()
  if (isHitMonitor) {
    monitor({ type: 1, params: { hit: isHitMonitor, scene, version } })
  }
  return {
    hit: isHitMonitor,
    scene
  }
}

/**
 * 快速加车监控方法
 * @param { String } type 监控类型
 * @param { Object } extendsData 扩展数据
 */

// TO debugger monitor({ type: 1/2/3/4 })
const monitor = ({ type, params = {} }) => {
  const { hit = false, ...args } = params
  if (!hit) {
    return false
  }

  const { SiteUID = '' } = gbCommonInfo || {}

  if (!monitorMap[type]) {
    console.error('monitor type is not defined')
    return false
  }

  const tags = {
    // 指标纬度
    SiteUID,
    ...args
  }

  SIMetric.metricCount(
    {
      metric_name: monitorMap[type], // 指标名称
      tags,
      message: 'quick_add_monitor'
    }
  )

}

/**
 * 快速加车错误日志上报
 */
const monitorLog = (step, message) => {
  SILogger.logInfo({
    tag: 'quick_add_err_log',
    message,
    params: {
      step, 
    }
  })
}

export { initMonitor, monitor, monitorLog }
