import { h } from 'vue'
import BuyNowButton from './BuyNowButton.vue'
export function Button(props) {
  const { context, useModules, text } = props
  return (
    <BuyNowButton
      checkoutNo={props.checkout_no}
      needJump={props.needJump}
      autoGetCoupon={props.autoGetCoupon}
      autoUpdateCouponList={props.autoUpdateCouponList}
      analysisConfig={props.analysisConfig}
      text={text}
      context={context}
      useModules={useModules}
    ></BuyNowButton>
  )
}
