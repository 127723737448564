import EstiamtedTag from 'public/src/pages/goods_detail_v2/innerComponents/top/Price/components/EstimatedTag/index.vue'
import { h } from 'vue'

export function EstimatedTips(props) {
  function clickEstimatedTag() {
    window?._gb_app_?.$store?.dispatch?.('productDetail/common/add_on_item_open', {})
  }
  return (
    <EstiamtedTag
      estimatedInfo={props.estimatedInfo}
      isNoThresholdFloor={true}
      onClickEstimatedTag={clickEstimatedTag}
    ></EstiamtedTag>
  )
}
