import { Gifting } from './Gifting.jsx'

/**
 * 礼品包材插件
 * @param {Object} giftInfo 礼品包材信息
 * @param {Array} giftInfo.giftList 礼品列表
 * @param {Boolean} giftInfo.giftSelectedStatus 是否选中礼品
 * @param {Array} giftInfo.giftSelectedList 选中的礼品列表
 * @param {String} giftInfo.giftLowestPrice 礼品最低价格
 * @param {String} giftInfo.giftSelectedPrices 选中的礼品价格
 * @param {Object} giftInfo.productItemsLanguage 产品语言包
 * @description 目前只在商详页主商品加车场景下使用
 */
export const giftPlugin = (giftInfo) => {
  let pluginContext = null
  return {
    name: 'Gifting',
    register: (context) => {
      pluginContext = context
    },
    renderBelowSalesAttr: (context) => {
      return Gifting({ context, pluginContext, giftInfo })
    },
    callbacks: {
      onAddCartFinished(data) {
        // 加车成功后，如果有选中礼品包材，则批量加车
        if(data.isSuccess && giftInfo?.giftSelectedList?.length && giftInfo?.giftSelectedStatus) {
          setTimeout(() => {
            // 包材料商品批量加车
            window?._gb_app_?.$store?.dispatch?.('productDetail/gift/batch_gift_add_to_bag', {
              gift_selected_list: giftInfo?.giftSelectedList,
            })
          }, 1500)
        }
      },
    },
  }
}
