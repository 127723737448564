<script lang="jsx">
import { Dialog } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { markPoint } from 'public/src/services/mark/index.js'
import OneClickPaySuccessIcon from 'public/src/pages/goods_detail/svg/OneClickPaySuccessIcon'

const { langPath } = gbCommonInfo
const LIST_MAX_SHOW_ORDER = 6

export default {
  name: 'OrderDialog',
  components: {
    SDialog: Dialog,
    OneClickPaySuccessIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    relativeOrders: {
      type: Array,
      default: () => ([])
    },
    currentOrderNum: {
      type: [String, Number],
      default: '',
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      closeTimer: -1,
      localVisible: false,
    }
  },
  computed: {
    // 展示的相关订单列表
    displayOrderList() {
      const { relativeOrders, currentOrderNum } = this
      if (Array.isArray(relativeOrders) && relativeOrders.length) {
        // 主订单一般为第一项
        let mainOrder = null
        const restOrders = []
        for (let i = 0; i < relativeOrders.length; i++) {
          const { goods_list = [], billno } = relativeOrders[i] || {}
          // 命中主订单
          if (billno === currentOrderNum) {
            mainOrder = { goods_list, billno }
          } else {
            if (!Array.isArray(goods_list)) {
              continue
            }
            const sum = goods_list.reduce((pre, cur) => {
              const { order_goods_sum = 0 } = cur || {}
              const curNum = Number(order_goods_sum) || 0
              return pre + curNum
            }, 0)
            restOrders.unshift({
              image: goods_list[0]?.goods_thumb || '',
              sum,
              billno,
            })
          }
        }
        // 若未命中订单号，则默认第一项为主订单
        if (!mainOrder) {
          mainOrder = { ...relativeOrders[0] }
          if (!mainOrder) {
            return restOrders
          }
          restOrders.pop()
        }

        // 主订单拆解
        const mainOrderProducts = []
        mainOrder.goods_list?.forEach(({ order_goods_sum = 1, goods_thumb = '' } = {}) => {
          mainOrderProducts.push({
            image: goods_thumb,
            sum: Number(order_goods_sum) || 1,
            billno: mainOrder.billno
          })
        })

        // 构造渲染订单
        const renderList = [
          ...restOrders,
          ...mainOrderProducts,
        ]

        const total = renderList.length
        // 8个以内，原样展示，超过8个只显示8个
        if (total <= LIST_MAX_SHOW_ORDER) {
          return renderList
        }

        // 超过8个，筛选前7项正常展示，最后一项收起
        const overflowCount = Math.max(total - LIST_MAX_SHOW_ORDER + 1, 1)
        const edgeOrder = renderList[LIST_MAX_SHOW_ORDER - 1]
        if (!edgeOrder) {
          return renderList.slice(0, LIST_MAX_SHOW_ORDER - 1)
        }
        edgeOrder.overOrders = overflowCount
        return [
          ...renderList.slice(0, LIST_MAX_SHOW_ORDER - 2),
          edgeOrder,
        ]
      }
      return []
    },
  },
  watch: {
    // 计时关闭对话框
    visible(isVisible) {
      this.localVisible = isVisible
      const { closeTimer, currentOrderNum, relativeOrders } = this
      clearTimeout(closeTimer)
      if (isVisible) {
        this.closeTimer = setTimeout(() => {
          this.close()
        }, 5e3)
        daEventCenter.triggerNotice({
          daId: '1-6-1-111',
          extraData: {
            scene: 'one_tap_pay',
            style: 'snakebar',
            order_no: currentOrderNum || '',
            merge_count: relativeOrders?.length || 1,
          },
        })
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.closeTimer)
  },
  methods: {
    // 渲染关联订单
    renderRelativeOrders() {
      const { displayOrderList, renderOrderItem } = this
      if (!Array.isArray(displayOrderList) || !displayOrderList.length) {
        return null
      }

      return (
        <ul class="image-box">
          {displayOrderList.map((order, index) => {
            return renderOrderItem(order, index)
          })}
        </ul>
      )
    },
    // 渲染关联订单项
    renderOrderItem(order, index) {
      if (!order) return null
      return (
        <li key={index}>
          {/** 主图 */}
          <img
            class="main-image"
            src={order.image}
          />
          {order.overOrders && (
            <div class="sum-order-cover">
              {/** 剩余订单数量蒙版 */}
              +{ order.overOrders }
            </div>
          )}
          {!order.overOrders && order.sum > 1 && (
            <div class="sum-cover">
              {/** 数量蒙版 */}
                X{ order.sum }
            </div>
          )}
        </li>
      )
    },
    // 订单对话框点击，跳转订单页
    onDialogClick() {
      // 埋点
      const { currentOrderNum, relativeOrders } = this
      daEventCenter.triggerNotice({
        daId: '1-6-1-112',
        extraData: {
          scene: 'one_tap_pay',
          style: 'snakebar',
          order_no: currentOrderNum,
          merge_count: relativeOrders?.length || 1,
          type: 'orders',
        },
      })
      const url = `${langPath}/user/orders/list?from=PageGoodsDetail`
      markPoint('toNextPageClick', 'public')
      window.location.href = url
    },
    // 关闭对话框
    close() {
      this.$emit('close')
    },
  },

  render() {
    const {
      localVisible,
      language,
      renderRelativeOrders,
      close,
      onDialogClick,
    } = this

    return (
      <s-dialog
        visible={localVisible}
        class="OCP-dialog"
        modal={false}
        append-to-body={true}
        onClose={close}
      >
        <div
          class="inner-box"
          onClick={onDialogClick}
        >
          <div class="level-1">
            <OneClickPaySuccessIcon />
            <i></i>
            <span class="title">{ language.SHEIN_KEY_PWA_23680 }</span>
          </div>

          <div class="level-2">
            <div>
              {/** 副标题 */}
              <p class="sub-title">
                { language.SHEIN_KEY_PWA_23681 }
              </p>
              {/** 相关订单列表 */}
              {renderRelativeOrders()}
            </div>
            <i></i>
            <div class="view-button">
              { language.SHEIN_KEY_PWA_20368 }
            </div>
          </div>
        </div>
      </s-dialog>
    )
  },
}
</script>

<style lang="less" scoped>
  .OCP-dialog {

    .title, .sub-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
    }

    .level-1, .level-2 {
      display: flex;
      align-items: center;
    }

    .level-1 {
      i {
        display: inline-block;
        width: .16rem;
        height: 1px;
      }
      span {
        display: inline-block;
        font-size: .373333rem;
        font-weight: 700;
        max-width: calc(100% - 0.16rem - 30px);
      }
    }

    .level-2 {
      margin-top: .213333rem;
      justify-content: space-between;
      align-items: center;

      .sub-title {
        font-size: .32rem;
        font-weight: 400;
        margin-bottom: 8px;
      }

      i {
        display: inline-block;
        width: .213333rem;
        height: 1px;
      }

      .view-button {
        min-width: 2.133333rem;
        height: .64rem;
        white-space: nowrap;
        box-sizing: border-box;
        border: 1px solid #fff;
        font-size: .32rem;
        font-weight: 400;
        text-align: center;
        line-height: .64rem;
        color: #fff;
        padding: 0 .213333rem;
        .text-overflow();
      }
    }

    // 相关订单列表
    .image-box {
      display: flex;
      align-items: center;

      li {
        height: .96rem;
        width: .96rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .053333rem;
        position: relative;
      }
      .main-image {
        display: block;
        width: .96rem;
      }

      .sum-cover, .sum-order-cover {
        position: absolute;
        bottom: 0;
        left: 0;
        width: .96rem;
        background-color: rgba(34, 34, 34, 0.6);
        font-weight: 700;
        color: #fff;
        text-align: center;
      }
      .sum-cover {
        height: .426667rem;
        font-size: .266667rem;
        line-height: .426667rem;
      }

      .sum-order-cover {
        height: .96rem;
        font-size: .373333rem;
        line-height: .96rem;
      }
    }


        // 隐藏头部
    /deep/ .S-dialog__top {
      display: none;
    }

    // 重置dialog-body
    /deep/ .S-dialog__body {
      padding: .32rem;
      background-color: rgba(0, 0, 0, 0.8);
    }

    // 定位对话框
    /deep/ .S-dialog__wrapper {
      width: 100%;
      left: 0;
      transform: none;
      bottom: 1.36rem;
      top: unset;
      max-width: unset;
      max-height: unset;
    }
  }
</style>
