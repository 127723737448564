var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"productEstiamtedTagOld",style:({
    ..._setup.strategyStyle,
    ..._setup.containerStyle
  }),on:{"click":function($event){$event.stopPropagation();return _setup.emits('clickEstimatedTag')}}},[(_setup.isNEstiamted)?[_c('p',{staticClass:"productEstiamtedTagOld__font-12px"},[_vm._v("\n      "+_vm._s(_setup.buyMultipleText.left)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"productEstiamtedTagOld__vertical",style:({ background: _setup.strategyStyle.color })}),_vm._v(" "),_c('p',{staticClass:"productEstiamtedTagOld__font-10px",domProps:{"innerHTML":_vm._s(_setup.buyMultipleText.right)}})]:(_setup.isCompliance || _setup.isSatisfy)?[_c('p',{staticClass:"productEstiamtedTagOld__font-14px",domProps:{"innerHTML":_vm._s(_setup.mainPrice)}}),_vm._v(" "),_c('div',{staticClass:"productEstiamtedTagOld__vertical",style:({
        background: _setup.strategyStyle.color
      })}),_vm._v(" "),_c('p',{staticClass:"productEstiamtedTagOld__font-10px productEstiamtedTagOld__text-overflow2"},[_vm._v("\n      "+_vm._s(_setup.text)+"\n    ")])]:(_setup.isNoSatisfy)?[_c('p',{staticClass:"productEstiamtedTagOld__font-12px"},[_vm._v("\n      "+_vm._s(_setup.mainPrice)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"productEstiamtedTagOld__vertical",style:({
        background: _setup.strategyStyle.color
      })}),_vm._v(" "),_c('p',{staticClass:"productEstiamtedTagOld__font-10px productEstiamtedTagOld__text-overflow"},[_vm._v("\n      "+_vm._s(_setup.text)+"\n    ")])]:_vm._e(),_vm._v(" "),(_setup.countdownTipHtml)?_c('div',{staticClass:"productEstiamtedTagOld__countdown-wrap"},[_vm._v("\n    "+_vm._s(_setup.language.SHEIN_KEY_PWA_25725)+" \n    "),_c('div',{staticClass:"productEstiamtedTagOld__countdown-time",domProps:{"innerHTML":_vm._s(_setup.countdownTipHtml)}})]):_vm._e(),_vm._v(" "),(_setup.hasLink)?_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2 productEstiamtedTagOld__icon-link"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }