import BelowSalesAttrGift from './BelowSalesAttrGift'
import { h } from 'vue'

export function Gifting(data) {

  function selectGiftList({ selectedStatus, selectedProducts }) {
    data.giftInfo.giftSelectedStatus = selectedStatus
    data.giftInfo.giftSelectedList = selectedProducts
  }

  function updateGiftInfo({ giftList, lowestPrice, giftSelectedPrices }) {
    if (giftList !== undefined) {
      data.giftInfo.giftList = giftList
    }
    if (lowestPrice !== undefined) {
      data.giftInfo.giftLowestPrice = lowestPrice
    }
    if (giftSelectedPrices !== undefined) {
      data.giftInfo.giftSelectedPrices = giftSelectedPrices
    }
  }


  return (
    <BelowSalesAttrGift context={data.context} pluginContext={data.pluginContext} giftInfo={data.giftInfo} selectGiftList={selectGiftList} updateGiftInfo={updateGiftInfo} />
  )
}
