/**
 * @file 领券
 * https://soapi.sheincorp.cn/application/1406/versions/test26/post_product_coupon_batch_bind_coupons/doc
 * import getCoupon from 'public/src/pages/product_app/store/modules/product_detail/utils/getCoupon.js'
*/

import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { Toast } from '@shein/sui-mobile'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'

export default async ({
  couponCodes,
  language,
  needSuccessToast,
  needFailToast,
  successToastDelay
}) => {
  if (!couponCodes || !couponCodes.length) return false
  if (!isLogin()) return false
  let res = await schttp({
    method: 'POST',
    url: '/product/coupon/batch_bind_coupons',
    data: { coupon_code: couponCodes },
    useBffApi: true,
  })
  let { info: { failureList, successList }, code } = res
  let isSuccess = (() => {
    if (code != 0) return false
    if (successList.length !== couponCodes.length) return false
    for (let i of successList) {
      if (!couponCodes.includes(i.couponCode)) {
        return false
      }
    }
    return true
  })
  if (isSuccess && needSuccessToast) {
    setTimeout(() => {
      Toast({
        content: language.SHEIN_KEY_PWA_25104 || 'We automatically help you to claim coupons',
        iconClass: 'suiiconfont sui_icon_coupon_discount_16px',
      })
    }, successToastDelay)
  }
  if (!isSuccess && needFailToast) {
    let code = failureList?.find?.(item => couponCodes.includes(item.couponCode))?.code
    if (code) Toast({ content: language[code] })
  }
  return isSuccess
}

/**
 * 
 * @param {Object} params
 * @param {Array} params.couponInfo - 优惠券列表
 * @param {Object} params.estimatedInfo - 到手价信息
 * @returns 
 */
export const autoGetCoupon = async ({ couponInfo, estimatedInfo }) => {
  if (!isLogin()) return false
  if (!estimatedInfo) return false

  /**
   * 符合条件可以领取的优惠券列表
   */
  let couponCodes = []
  couponInfo?.value?.forEach((item) => {
    const { couponStatus, businessExtension, coupon } = item || {}
    const { isBindingCoupon, isFreeShipping, isFreeShippingThreshold, isOptimalCoupon } = businessExtension?.productDetail || {}
    if (couponStatus == 2 && isBindingCoupon == 1) {
      if ((isFreeShipping && isFreeShippingThreshold) || isOptimalCoupon) {
        couponCodes.push(coupon)
      }
    }

  })

  if (!couponCodes.length) return

  // 请求领取优惠券接口，进行券绑定
  let res = await schttp({
    method: 'POST',
    url: '/product/coupon/batch_bind_coupons',
    data: { coupon_code: couponCodes },
    useBffApi: true,
  })
  let { info: { successList }, code } = res
  let isSuccess = true
  if (code == 0) {
    isSuccess = false
  }
  if (successList.length !== couponCodes.length) {
    isSuccess = false
  }
  for (let i of successList) {
    if (!couponCodes.includes(i.couponCode)) {
      isSuccess = false
    }
  }
  res.isSuccess = isSuccess
  // 领券埋点
  daEventCenter.triggerNotice({
    daId: '1-6-4-42',
    extraData: {
      coupon_id: couponCodes.join(`,`),
      status: isSuccess ? 1 : 0,
      abtest: abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
    }
  })
  return res
}
