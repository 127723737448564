import { findSimilarPlugin } from './findSimilar'
import { oneClickPayPlugin } from './oneClickPay'
import { buyNowPlugin } from './buyNow'
import { giftPlugin } from './gift'
import { salesAttrUpdatePlugin } from './salesAttrUpdate'
import { estimatedTipsPlugin } from './estimatedTips'
import { addCartRecommendPlugin } from './addCartRecommend'
import { syncCartInfoPlugin } from './syncCartInfo'
import { syncDetailsDataPlugin } from './syncDetailsData'
import { syncGlobalDataPlugin } from './syncGlobalData'
import { hiddenAddCartTipsPlugin } from './live'
import { onMonitoringPlugin } from './onMonitoring'
import { definableEventPlugin } from './definableEvent'

export const plugins = {
  findSimilarPlugin,
  oneClickPayPlugin,
  buyNowPlugin,
  giftPlugin,
  salesAttrUpdatePlugin,
  estimatedTipsPlugin,
  addCartRecommendPlugin,
  syncCartInfoPlugin,
  syncDetailsDataPlugin,
  syncGlobalDataPlugin,
  hiddenAddCartTipsPlugin,
  onMonitoringPlugin,
  definableEventPlugin,
}
