
import { h } from 'vue'
export function DisableButton(text) {
  return (
    <button
      class="mshe-btn-black add-btn disabled"
      role="button"
      aria-label="Update"
    >
      {text || 'Update'}
    </button>
  )
}
